<template>
    <div class="action-workday">
        <template>
            <div class="message">
                <div class="message-content">
                    <div>
                        <p>궁금한게 있으면 편하게 말씀해주세요 :)</p>
                    </div>
                    <div class="grey-box m-t-8">
                        <p class="c-grey-07 spoqa-f-bold f-12">응대 가능시간</p>
                        <p class="f-16 spoqa-f-bold c-black">평일 오전 9시~오후 6시</p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionOpenChatSupport',
}
</script>

<style scoped lang="scss">
.action-workday {
    padding-top: 16px;

    .grey-box {
        background: #f1fffc;
    }
}
</style>
